import React from 'react';
import { BarChart } from '@mui/x-charts';

const ListItem = ({ word, probabilities }) => {
  const splitBins = 7
  const minBinV = 0.3
  const maxBinV = 1
  const binSize = (maxBinV - minBinV) / splitBins
  // Prepare data for the histogram
  const bins = Array.from({ length: (maxBinV - minBinV) / binSize }, (_, index) => ({
    range: `${(minBinV + index * binSize).toFixed(2)}-${(minBinV + (index + 1) * binSize).toFixed(2)}`,
    count: 0,
  }));

  // Aggregate probabilities into bins
  probabilities.forEach(probability => {
    const binIndex = Math.floor((probability - minBinV) / binSize);
    if (binIndex >= 0 && binIndex < bins.length) {
      bins[binIndex].count += 1;
    }
  });

  return (
        <BarChart
        width={800}
        height={200}
        series={[{ data: bins.map(bin =>bin.count), type: 'bar' }]}
        xAxis={[{ scaleType: 'band', data: bins.map(bin => bin.range), label: `${word} probabilities`}]}
        title={word}
      >
      </BarChart>
  );
};

export default ListItem;
