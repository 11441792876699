import React, { useEffect, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import { HandLandmarker, FaceLandmarker, DrawingUtils, FilesetResolver } from "@mediapipe/tasks-vision";
import { HAND_CONNECTIONS } from '@mediapipe/hands';
import { v4 as uuidv4 } from 'uuid';
import ListItem from './components/listItem';

const REYE = [33, 7, 163, 144, 145, 153, 154, 155, 133, 246, 161, 160, 159, 158, 157, 173];
const LEYE = [263, 249, 390, 373, 374, 380, 381, 382, 362, 466, 388, 387, 386, 385, 384, 398];
const NOSE = [1, 2, 98, 327];
const LIP = [
  0, 61, 185, 40, 39, 37, 267, 269, 270, 409, 291, 146, 91, 181, 84, 17, 314, 405, 321, 375,
  78, 191, 80, 81, 82, 13, 312, 311, 310, 415, 95, 88, 178, 87, 14, 317, 402, 318, 324, 308
];
let concat60Landmarks = [];
const parseResults = (faceResults, handResults, setFeaturesToDisplay) => {
  const concatLandmarks = {}


  // Parse face landmarks
  if (faceResults?.faceLandmarks) {
    faceResults.faceLandmarks.forEach(landmarks => {
      // Concatenate x, y, z coordinates of specific landmarks
      concatLandmarks.lip = LIP.map(index => landmarks[index]);
      concatLandmarks.nose = NOSE.map(index => landmarks[index]);
      concatLandmarks.reye = REYE.map(index => landmarks[index]);
      concatLandmarks.leye = LEYE.map(index => landmarks[index])
    });
  }

  // Parse hand landmarks

  if (handResults?.handednesses) {
    handResults.handednesses.forEach(handness => {
      if (handness[0].categoryName === 'Left') {
        // HACK because inversion
        concatLandmarks.rhand = handResults.landmarks[handness[0].index];
      } else {
        // HACK because inversion
        concatLandmarks.lhand = handResults.landmarks[handness[0].index];
      }
    });
  }
  concat60Landmarks.push(concatLandmarks);
  setFeaturesToDisplay(JSON.stringify(Object.keys(concatLandmarks)));
  concat60Landmarks = concat60Landmarks.slice(-20)

  return concat60Landmarks;
};



const App = () => {
  const [buttonText, setButtonText] = useState("ENABLE WEBCAM");
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [webcamRunning, setWebcamRunning] = useState(false);
  const showFeaturesRef = useRef(true); // Mutable reference to showFeatures
  const [showFeatures, setShowFeatures] = useState(true);
  const [handLandmarker, setHandLandmarker] = useState(null);
  const [faceLandmarker, setFaceLandmarker] = useState(null);
  const [initializingWebcam, setInitializingWebcam] = useState(false);
  const [textToDisplay, setTextToDisplay] = useState({ words: [], sentence: '' });
  const [featuresToDisplay, setFeaturesToDisplay] = useState("");
  const flipRef = useRef(false);
  const [flip, setFlip] = useState(false);
  const resetRef = useRef(false);
  const sendRef = useRef(true);
  const [send, setSend] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);

  const storedUserId = localStorage.getItem('userId');
  if (!storedUserId) {
    // If userId doesn't exist, generate a new one and store it
    const newUserId = uuidv4();
    localStorage.setItem('userId', newUserId);
  }
  const storedBackendUrl = localStorage.getItem('backendUrl');
  // if (!storedBackendUrl) {
  //   localStorage.setItem('backendUrl', 'https://adrien.backend.ava.me');
  // }

  useEffect(() => {
    showFeaturesRef.current = showFeatures; // Update the mutable reference to showFeatures
  }
    , [showFeatures]);
  useEffect(() => {
    sendRef.current = send;
  }
    , [send]);
  useEffect(() => {
    flipRef.current = flip;
  }
    , [flip]);


  useEffect(() => {
    const initLandmarkers = async () => {
      const vision = await FilesetResolver.forVisionTasks("https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.3/wasm");
      const handLandmarker = await HandLandmarker.createFromOptions(vision, {
        baseOptions: {
          modelAssetPath: `https://storage.googleapis.com/mediapipe-models/hand_landmarker/hand_landmarker/float16/1/hand_landmarker.task`,
          delegate: "GPU"
        },
        runningMode: "VIDEO",
        numHands: 2
      });
      const faceLandmarker = await FaceLandmarker.createFromOptions(vision, {
        baseOptions: {
          modelAssetPath: `https://storage.googleapis.com/mediapipe-models/face_landmarker/face_landmarker/float16/1/face_landmarker.task`,
          delegate: "GPU"
        },
        outputFaceBlendshapes: true,
        runningMode: "VIDEO",
        numFaces: 1
      });
      setHandLandmarker(handLandmarker);
      setFaceLandmarker(faceLandmarker);
    };

    initLandmarkers();

    return () => {
      if (handLandmarker) handLandmarker.close();
      if (faceLandmarker) faceLandmarker.close();
    };
  }, []);

  useEffect(() => {
    if (!webcamRunning || !handLandmarker || !faceLandmarker) return;

    const intervalId = setInterval(() => {
      predictWebcam();
    }, 50);

    return () => clearInterval(intervalId);
  }, [webcamRunning, handLandmarker, faceLandmarker]);

  const predictWebcam = () => {

    const video = videoRef.current;
    const canvas = canvasRef.current;
    if (!video || !canvas) return;

    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    const canvasContext = canvas.getContext('2d');
    if (!canvasContext) return;

    // Flip the image horizontally
    if (flipRef.current) {
      canvasContext.translate(canvas.width, 0);
      canvasContext.scale(-1, 1);
    }
    canvasContext.save();
    canvasContext.clearRect(0, 0, canvas.width, canvas.height);
    canvasContext.drawImage(video, 0, 0, canvas.width, canvas.height);

    const startTimeMs = performance.now();
    const handResults = handLandmarker.detectForVideo(video, startTimeMs);
    const faceResults = faceLandmarker.detectForVideo(video, startTimeMs);
    const resetValue = resetRef.current;
    const userId = localStorage.getItem('userId');
    const backendUrl = 'https://api.sign.ava-ai.team';

    if (sendRef.current) {
      fetch(`${backendUrl}/predict`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ features: parseResults(faceResults, handResults, setFeaturesToDisplay), reset: resetValue, userId }),
      })
        .then(response => {
          if (resetValue) resetRef.current = false;
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          setTextToDisplay(data);
        })
        .catch(error => {
          console.error('There was a problem with the fetch operation:', error);
        });
    }

    if (!showFeaturesRef.current) return;
    const drawingUtils = new DrawingUtils(canvasContext);
    if (handResults?.landmarks) {
      for (const landmarks of handResults.landmarks) {
        drawingUtils.drawConnectors(landmarks, HAND_CONNECTIONS, {
          color: "#00FF00",
          lineWidth: 5
        });
        drawingUtils.drawLandmarks(landmarks, { color: "#FF0000", lineWidth: 2 });
      }
    }

    if (handResults?.landmarks) {
      for (const landmarks of handResults.landmarks) {

        drawingUtils.drawLandmarks(landmarks, { color: '#FF0000', radius: 3 });
        drawingUtils.drawConnectors(landmarks, HAND_CONNECTIONS, { color: '#FF0000' });
      }
    }

    if (faceResults?.faceLandmarks) {

      for (const landmarks of faceResults?.faceLandmarks) {

        drawingUtils.drawConnectors(
          landmarks,
          FaceLandmarker.FACE_LANDMARKS_TESSELATION,
          { color: "#C0C0C070", lineWidth: 1 }
        );
        drawingUtils.drawConnectors(
          landmarks,
          FaceLandmarker.FACE_LANDMARKS_RIGHT_EYE,
          { color: "#FF3030" }
        );
        drawingUtils.drawConnectors(
          landmarks,
          FaceLandmarker.FACE_LANDMARKS_RIGHT_EYEBROW,
          { color: "#FF3030" }
        );
        drawingUtils.drawConnectors(
          landmarks,
          FaceLandmarker.FACE_LANDMARKS_LEFT_EYE,
          { color: "#30FF30" }
        );
        drawingUtils.drawConnectors(
          landmarks,
          FaceLandmarker.FACE_LANDMARKS_LEFT_EYEBROW,
          { color: "#30FF30" }
        );
        drawingUtils.drawConnectors(
          landmarks,
          FaceLandmarker.FACE_LANDMARKS_FACE_OVAL,
          { color: "#E0E0E0" }
        );
        drawingUtils.drawConnectors(
          landmarks,
          FaceLandmarker.FACE_LANDMARKS_LIPS,
          { color: "#E0E0E0" }
        );
        drawingUtils.drawConnectors(
          landmarks,
          FaceLandmarker.FACE_LANDMARKS_RIGHT_IRIS,
          { color: "#FF3030" }
        );
        drawingUtils.drawConnectors(
          landmarks,
          FaceLandmarker.FACE_LANDMARKS_LEFT_IRIS,
          { color: "#30FF30" }
        );
      }
    }
  };
  const handleWordClick = (index) => {
    setCurrentIndex(index);
    console.log(index, textToDisplay?.probs?.length, textToDisplay?.words?.length);
  };

  const enableWebcam = async () => {

    if (webcamRunning) {
      setShowFeatures(show => !show); // Toggle the state of showFeatures
      setButtonText(showFeatures ? "Show features" : "Hide features"); // Update Button text based on the toggled state
    } else {
      if (!initializingWebcam) {
        setInitializingWebcam(true); // Set the initializingWebcam state to true when starting the process
        try {
          const stream = await navigator.mediaDevices.getUserMedia({ video: true });
          videoRef.current.srcObject = stream;
          videoRef.current.play();
          setWebcamRunning(true);
          setButtonText("Hide features");
        } catch (error) {
          console.error("Error accessing webcam:", error);
        } finally {
          setInitializingWebcam(false); // Reset the initializingWebcam state when the process is finished
        }
      }
    }
  };

  return (
    <div>
      <h2>Demo: AvaSign Detection</h2>
      <Button sx={{ mr: 2 }} variant="contained" onClick={enableWebcam}>{buttonText}</Button>
      {webcamRunning && (
        <>
          <Button sx={{ mr: 2 }} variant="contained" onClick={() => setFlip(!flip)}>Flip</Button>
          <Button sx={{ mr: 2 }} variant="contained" onClick={() => setSend(!send)}>
            {send ? 'Stop sending' : 'Start sending'}
          </Button>
        </>
      )}
      <div style={{ display: 'flex' }}>
        <div style={{ position: 'relative' }}>
          <video ref={videoRef} style={{ position: 'absolute' }} autoPlay playsInline />
          <canvas ref={canvasRef} className="output_canvas" style={{ position: 'absolute', left: '0px', top: '0px' }} />
        </div>
        <div style={{ marginLeft: '700px' }}>
          <h3>Features:</h3>
          <p>{featuresToDisplay}</p>
          {textToDisplay?.probs?.length > currentIndex && (
            <ListItem probabilities={textToDisplay?.probs[currentIndex]} word={textToDisplay.words[currentIndex]} />)}
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Button sx={{ mr: 2 }} variant="outlined" onClick={() => { resetRef.current = true; setCurrentIndex(0) }}>Reset</Button>
            <h3>Sequences of signs:</h3>
            <div>
              {textToDisplay?.words.map((word, index) => (
                <span key={index}>
                  <span onClick={() => handleWordClick(index)} style={{ cursor: 'pointer', textDecoration: index === currentIndex ? 'underline' : 'none' }}>
                    {word}
                  </span>
                  {index !== textToDisplay.words.length - 1 && ' - '}
                </span>
              ))}
            </div>
          </div>
          <h3>Meaning:</h3>
          <p>{textToDisplay?.sentence}</p>
        </div>
      </div>
    </div>
  );
};

export default App;
